<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'gruender-rakete',
  primary_color: '#9933FF',
  secondary_color: '#32FF99',
  header: {
    background: {
      image: `/projekt/gruender-rakete/elbworx-projekt-gruender-rakete-header-2421.webp`,
      video: `/projekt/gruender-rakete/elbworx-gr-1080.mp4`,
    },
    project: 'Gründer-Rakete',
    headline: 'Ideen starten durch',
    headline_class: 'text-secondary',
    info_card: {
      description:
        'Gründerberatung für Start-ups aus Dresden mit dem Potenzial für Erfolgsgeschichten',
      client: 'Gründer-Rakete',
      roles: ['Logoentwicklung', 'Corporate Design', 'Webdesign'],
      project_duration: 'Seit 2021 bis 2022',
      project_url: 'https://gruender-rakete.de/',
      custom_button_class: 'bg-[#051F55]',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="!text-primary">
        Der Erfolg der Gründer-Rakete geht Hand in Hand mit dem Erfolg ihrer Partner
      </h3>

      <p>
        Eines der Projekte, das uns in den letzten Monaten bei Laune gehalten hat, ist für die
        Gründerrakete entstanden. Nicht nur als Kunde arbeiten wir gern mit den Raketen aus Dresden
        zusammen sondern geben auch den Gründern und Gründerinnen gern Starthilfe. So ist aus einem
        Kunde auch ein Partner geworden. Wir haben vom Logo über die Visitenkarte und der Bildwelt
        auch die Webseite einmal neu gedacht. Besondere Herausforderung war es für uns die
        verschiedenen Firmen zu verbinden und trotzdem hohe Wiedererkennbarkeit zu schaffen.
      </p>
    </template>

    <Padding class="flex justify-center pb-8">
      <Device
        class="w-full max-w-5xl"
        :element="{
          device_type: 'desktop',
          frame_color: '#9933FF',
        }"
      >
        <video
          :src="`${projectPath}elbworx-gr-1080-website.mp4`"
          :poster="`${projectPath}elbworx-projekt-gruender-rakete-header-2421.webp`"
          muted
          class="h-full w-full object-cover"
          autoplay
          loop
          playsinline
        />
      </Device>
    </Padding>

    <div>
      <Tilt>
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-gruender-rakete-team-1909.webp`"
        />
      </Tilt>
      <Tilt contain class="-mt-tilt bg-secondary 2xl:px-40">
        <Padding
          class="mx-auto flex w-full justify-between gap-6 py-12 font-mono text-2xl font-semibold max-lg:flex-col lg:gap-8 lg:py-40"
        >
          <div class="flex-1">
            <div class="flex h-full flex-col items-center justify-between gap-8 lg:gap-24">
              <div class="w-full">
                <Image
                  class="h-full max-h-36 w-full object-contain sm:max-h-48 lg:max-h-80"
                  :src="`${projectPath}Orbit.svg`"
                />
              </div>
              Orbit
            </div>
          </div>
          <div>
            <div class="mx-auto h-full w-28">
              <div class="flex h-full flex-col items-center justify-center gap-8 lg:gap-24">
                <div class="mx-auto flex h-full max-h-80 w-full items-center">
                  <Image :src="`${projectPath}Plus.svg`" />
                </div>
                <span class="max-lg:hidden lg:invisible" aria-hidden="true">A</span>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex h-full flex-col items-center justify-between gap-8 lg:gap-24">
              <div class="w-full">
                <Image
                  class="h-full max-h-36 w-full object-contain sm:max-h-48 lg:max-h-80"
                  :src="`${projectPath}elbworx-projekt-gruender-rakete-aurora-960.webp`"
                />
              </div>
              Aurora
            </div>
          </div>
          <div>
            <div class="mx-auto h-full w-28">
              <div class="flex h-full flex-col items-center justify-center gap-8 lg:gap-24">
                <div class="mx-auto flex h-full max-h-80 w-full items-center">
                  <Image :src="`${projectPath}Plus.svg`" />
                </div>
                <span class="max-lg:hidden lg:invisible" aria-hidden="true">A</span>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex h-full flex-col items-center justify-between gap-8 lg:gap-24">
              <div class="w-full">
                <Image
                  class="h-full max-h-36 w-full object-contain sm:max-h-48 lg:max-h-80"
                  :src="`${projectPath}Konstruktion.svg`"
                />
              </div>
              Konstruktion
            </div>
          </div>
        </Padding>
      </Tilt>
    </div>

    <div class="-mt-4">
      <Tilt>
        <Grid class="-mt-tilt items-center bg-primary">
          <div class="col-span-7 h-full min-h-[30rem]">
            <Tilt class="relative h-full">
              <BackgroundMedia
                :background-video="`${projectPath}elbworx-gr-1080-computer.mp4`"
                :background-image="`${projectPath}elbworx-projekt-gruender-rakete-offen_fuer_neues.webp`"
                class="h-full w-full"
                video-object-fit="object-none"
              />
            </Tilt>
          </div>
          <div
            class="col-span-5 flex h-full items-center justify-center bg-primary pb-tilt-container md:pt-tilt"
          >
            <Padding>
              <Text class="py-10 !text-white">
                <h3 class="!text-white">Volle Schubkraft</h3>
                <p>
                  Bei der Gestaltung der Bildwelt für die Gründer Rakete berücksichtigten wir das
                  Gedankenspiel den Gründer auf seine „Weltraumreise“ zu neuen Unbekannten zu
                  begleiten. Dieses Thema war für uns Gestalter natürlich eine Fundgrube für
                  kreative Ideen. So nutzten wir altes NASA Material als Quelle für
                  Gestaltungselemente. Das Raster welches auf Website und Printmaterialien vorkommt,
                  soll an das Kartografieren von Mond- und Planentoberflächen erinnern.
                </p>
              </Text>
            </Padding>
          </div>
        </Grid>
      </Tilt>
    </div>

    <Padding class="mt-8">
      <Image
        :src="`${projectPath}elbworx-projekt-gruender-rakete-logo.svg`"
        class="object-fit w-full py-12 md:p-24"
      />
      <Text class="mt-20 !max-w-5xl md:px-24">
        <h3 class="!text-primary">Das Logo</h3>
        <p>
          Das Logo ist eine Konstruktion aus verschiedenen Icons, die variabel zusammengesetzt
          werden können. Das Haupticon wird dabei zusammen mit den Initialen vereinfacht und
          fortgeführt. Das Logo ist metaphorisch als Raumschiff zu sehen, das an einzelnen Sterne
          andocken kann, um neue Wege zu gehen. Begleitet von einem Helfer (Laika) kann das
          Gründerschiff durchstarten.
        </p>
      </Text>
    </Padding>

    <Grid class="mt-36">
      <Padding only="left" class="col-span-1 place-self-end md:col-span-6">
        <Image
          :src="`${projectPath}elbworx-projekt-gruender-rakete-briefbogen-1751.webp`"
          class="w-full object-cover"
        />
      </Padding>
      <div class="col-span-1 md:col-span-6">
        <Image
          :src="`${projectPath}elbworx-projekt-gruender-rakete-visitenkarten-1920.webp`"
          class="w-full object-cover"
        />
      </div>
      <div class="col-span-1 md:col-span-9">
        <Image
          :src="`${projectPath}elbworx-projekt-gruender-rakete-logbuch-2560.webp`"
          class="w-full object-cover"
        />
      </div>
      <div class="col-span-1 place-self-center md:col-span-3">
        <Image
          :src="`${projectPath}elbworx-projekt-gruender-rakete-rakete_abstrakt.svg`"
          class="w-full object-contain max-md:max-h-80"
        />
      </div>
      <div class="col-span-1 md:col-span-12">
        <Image
          :src="`${projectPath}elbworx-projekt-gruender-rakete-jungholz-2560.webp`"
          class="w-full object-cover"
        />
      </div>
    </Grid>
  </Project>
</template>
